import "./styles.css";
import { Container } from "@mui/material";
import Login from "../Login";
import Dashboard from "../Dashboard";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AuthzCallbackTarget from "../AuthzCallbackTarget";

console.log("begin (re)loading App component...");

function App() {
  return (
    <BrowserRouter>
      <Container className="App">
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/Dashboard" element={<Dashboard />} />
          <Route
            path="/AuthzCallbackTarget"
            element={<AuthzCallbackTarget />}
          />
        </Routes>
      </Container>
    </BrowserRouter>
  );
}

export default App;
