import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

function Login() {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const codeParam = searchParams.get("code");
  const [tokens, setTokens] = useState({});
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const hostedUIRoute = "logout"; // 'oauth2/authorize' for login only (auto-auth across sessions; no initial logout)

  useEffect(() => {
    window.history.replaceState(null, "hide auth code", "/");
    const {
      REACT_APP_USER_POOL_DOMAIN,
      REACT_APP_USER_POOL_REGION,
      REACT_APP_APP_CLIENT_CALLBACK_URL,
      REACT_APP_CLIENT_ID,
      REACT_APP_API_URL,
      REACT_APP_COGNITO_AUTH_SCOPE,
    } = process.env;
    if (!codeParam) {
      const cognitoAuthUrl =
        `https://${REACT_APP_USER_POOL_DOMAIN}.` +
        `auth.${REACT_APP_USER_POOL_REGION}.` +
        `amazoncognito.com/${hostedUIRoute}?` +
        `client_id=${REACT_APP_CLIENT_ID}&` +
        `response_type=code&` +
        `scope=email+${REACT_APP_COGNITO_AUTH_SCOPE}+openid&` +
        `redirect_uri=${REACT_APP_APP_CLIENT_CALLBACK_URL}&` +
        `identity_provider=COGNITO`;
      window.location.replace(cognitoAuthUrl);
    } else {
      const getTokenUrl = REACT_APP_API_URL + "auth/token?code=" + codeParam;
      const fetchConfig = {
        method: "GET",
        mode: "cors",
        credentials: "include",
      };
      console.log("authtoken fetchConfig: ", fetchConfig);
      fetch(getTokenUrl, fetchConfig)
        .then(async function (response) {
          const responseJson = await response.json();
          if (responseJson.error) {
            setError(responseJson.error);
          } else {
            setTokens(responseJson);
            window.sessionStorage.setItem(
              "tokens",
              JSON.stringify(responseJson)
            );
            navigate("/Dashboard");
          }
        })
        .catch(function (err) {
          setError(err);
        });
    }
  }, [codeParam, navigate]); // maybe will have to set to empty array dependencies if we change url (without refresh, to remove visible code param)

  return (
    <div>
      <span>code was detected! {codeParam}</span>
      {tokens.access_token && <h1>you are authenticated!</h1>}
      {!codeParam && !tokens.access_token && <h1>loading login form</h1>}
      {codeParam && !tokens.access_token && <h1>signing in...</h1>}
      {error && <h1>error: {error}</h1>}
      <Link to="/Dashboard">Go to Main Page</Link>
    </div>
  );
}

export default Login;
