import { useEffect } from "react";

const AuthzCallbackTarget = () => {
  console.log("begin loading AuthzCallbackTarget component...");
  const queryParams = new URLSearchParams(window.location.search);
  const authCode = queryParams.get("code");
  console.log("authCode from queryParams: ", authCode);

  useEffect(() => {
    // if code is in query params then send the code to the parent window
    if (authCode != null) {
      window.opener.postMessage({ type: "googleAuthCode", authCode }, "*");
    }
    // if code is not in query params then send error to the parent window
    else {
      window.opener.postMessage(
        {
          type: "googleAuthCode",
          error: "auth code not sent to callback target",
        },
        "*"
      );
    }
    window.close();
  }, [authCode]);

  return <div></div>; // keep it empty so nothing flashes before popup closes
};

export default AuthzCallbackTarget;
