import axios from "axios";
import { useState, useEffect } from "react";

const Dashboard = () => {
  console.log("begin (re)loading Dashboard component...");

  const [googleAuthUrl, setGoogleAuthUrl] = useState("");

  // get access token from session storage
  const tokenRes = window.sessionStorage.getItem("tokens");
  const parsedTokens = JSON.parse(tokenRes);
  console.log("tokens from sessionStorage: ", parsedTokens);
  const accessToken = parsedTokens.tokens.data.access_token;

  useEffect(() => {
    // call API to get url for presenting Google authz UI to the user
    const url =
      "https://pqbaqotbgi.execute-api.us-east-1.amazonaws.com/auth/google/url";
    axios.get(url).then((res) => {
      console.log("googleauthurl res: ", res);
      const url = res.data.authorizeUrl;
      setGoogleAuthUrl(() => url);
    });
  }, []);

  const getAccountById = () => {
    const url =
      "https://pqbaqotbgi.execute-api.us-east-1.amazonaws.com/account/1234";
    const headers = { headers: { Authorization: `Bearer ${accessToken}` } };
    axios.get(url, headers).then((res) => {
      console.log("getAccountById res: ", res);
    });
  };

  const createNewAccount = () => {
    const url =
      "https://pqbaqotbgi.execute-api.us-east-1.amazonaws.com/account";
    const headers = { headers: { Authorization: `Bearer ${accessToken}` } };
    axios.post(url, { email: "johndoe@email.com" }, headers).then((res) => {
      console.log("createNewAccount res: ", res);
    });
  };

  const authorizeWithGoogle = () => {
    if (googleAuthUrl === "") return;
    const width = 500;
    const height = 600;
    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;
    const options = `width=${width},height=${height},left=${left},top=${top}`;
    const popup = window.open(googleAuthUrl, "Google OAuth", options);

    const receiveMessage = (event) => {
      if (event.origin !== window.location.origin || event.source !== popup) {
        return;
      }
      if (event.data.type === "googleAuthCode") {
        const authCodeFromPopup = event.data.authCode;
        console.log("authCodeFromPopup: ", authCodeFromPopup);
        window.removeEventListener("message", receiveMessage);
        popup.close();
        // send auth code to backend
        const url =
          "https://pqbaqotbgi.execute-api.us-east-1.amazonaws.com/auth/google/code";
        axios.post(url, { authCodeFromPopup }).then((res) => {
          console.log("googleauthcode res: ", res);
        });
      }
    };

    window.addEventListener("message", receiveMessage);
  };

  const clearCookies = (path = "") => {
    const url = `https://pqbaqotbgi.execute-api.us-east-1.amazonaws.com/${path}clearcookies`;
    // axios.get(url, { withCredentials: true }).then((res) => {
    //   console.log("clearcookies res: ", res);
    // });
    const fetchConfig = {
      method: "GET",
      mode: "cors",
      credentials: "include",
    };
    console.log("clearCookies fetchConfig: ", fetchConfig);
    fetch(url, fetchConfig).then((res) => {
      console.log("clearcookies res: ", res);
    });
  };

  return (
    <div>
      <h1>Dashboard</h1>
      <button onClick={authorizeWithGoogle}>
        Authorize for Gmail (i.e. get auth code)
      </button>
      <button onClick={getAccountById}>Get Account By Id</button>
      <button onClick={createNewAccount}>Create New Account</button>
      <button onClick={() => window.sessionStorage.clear()}>
        Clear Session Storage
      </button>
      <button onClick={() => clearCookies()}>Clear Cookies @ /</button>
      <button onClick={() => clearCookies("auth/")}>
        Clear Cookies @ /auth/
      </button>
    </div>
  );
};

export default Dashboard;
